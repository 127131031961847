import React, { useState, useEffect, useRef } from 'react';

interface ExpandingTextareaProps {
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const ExpandingTextarea: React.FC<ExpandingTextareaProps> = ({ placeholder, value, onChange }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState(value || '');

  useEffect(() => {
    if (textareaRef.current) {
      // Reset height - important to shrink on delete
      textareaRef.current.style.height = 'auto';
      // Set height to the scroll height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [text]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <textarea
      ref={textareaRef}
      className='form-control text-warning2'
      placeholder={placeholder}
      value={text}
      onChange={handleChange}
      rows={1} // Start with 1 row
      style={{
        width: '100%',
        resize: 'none',
        overflow: 'hidden',
        boxSizing: 'border-box',
      }}
    />
  );
};

export default ExpandingTextarea;
