import React, { useState, useEffect } from 'react';
import axiosInstance, { baseURL } from './axiosConfig';
import { Modal } from 'react-bootstrap';
import { ItemInterface, Variation, MenusResponse, Menus } from './types';
import CounterButtonAddCart from './CounterButtonAddCart';
import ExpandingTextarea from './ExpandingTextarea';
import styles from './Ullist.module.css';
import { AnyMxRecord } from 'dns';

interface ModalEditProps<T extends ItemInterface> {
  isOpen: boolean;
  onClose: () => void;
  onUpdateCart: (cartItemId:string, item: ItemInterface, quantity: number, totalprice: number, note: string, select_variations: string[]) => void;
  onDeleteItem:  (itemToRemove: ItemInterface) => void;
  item: T;
  variation: Variation[];
  loadItems: Menus[];
} 

const ModalEdit = <T extends ItemInterface>({
  isOpen,
  onClose,
  onUpdateCart,
  onDeleteItem,
  item,
  variation,
  loadItems,
}: ModalEditProps<T>) => {
  const [inputText, setInputText] = useState('');
  const [isClosing, setIsClosing] = useState(false);
  const [optionPrice, setOptionPrice] = useState<number>(0);
  const [selectVariations, setSelectVariations] = useState<string[] | null>([]);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState<{ [key: string]: string[] }>({});
  const [radioGroups, setRadioGroups] = useState<Record<string, { value: string | null, price: number }>>({});

  const handleUpdateCart = (cartItemId:string, item: ItemInterface, quantity: number, totalprice: number, note: string,select_variations: string[],  optionsData: { 
    variationID: string | number;
    required: boolean;
    multiple: boolean;
  }[]) => {
  
    const missingRequiredVariation = optionsData.some(({ variationID, required, multiple }) => {
      if (!required) return false; // Skip optional variations
      const groupKey = required && !multiple
        ? `radio-group-${variationID}` 
        : `checkbox-group-${variationID}`;
  
      const checkedInGroup = checkedCheckboxes[groupKey];
       // For radio buttons, we need to check if any option is selected
      if (required && !multiple) {
        return !checkedInGroup || checkedInGroup.length === 0;
      }
  
      // For checkboxes, ensure at least one option is selected
      return !checkedInGroup || checkedInGroup.length === 0;
    });
  
    if (missingRequiredVariation) {
      alert('กรุณาระบุตัวเลือก');
      return; // Stop execution if validation fails
    }

    onUpdateCart(cartItemId, item, quantity, totalprice, note, select_variations);
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 200); // Match the timeout to the animation duration
  };

  const handleCloseModal = () => {
    setInputText('');
    setSelectVariations(null);
    setOptionPrice(0);
    setRadioGroups({});
    setCheckedCheckboxes({});
    onClose();
  };
  useEffect(() => {
    if(isOpen && item) {
      setInputText(item.note!);
      variationSelected(item);
    }
    else{
      resetSelectItem();
    }
  }, [isOpen,item]);

  const handleOptionPrice = (event: React.ChangeEvent<HTMLInputElement>, getVariation: any , price: number, checkboxVal: string) => {
    const { value, name, checked } = event.target;
    const maxCheckboxesPerGroup = getVariation?.max ?? 2;
    setCheckedCheckboxes(prevChecked => {
      const checkedInGroup = prevChecked[name] || [];

      if (checked && checkedInGroup.length >= maxCheckboxesPerGroup) {
        event.target.checked = false;
        return prevChecked;
      }

      if (checked) {
        // Add the value to the group
        setSelectVariations(selectVariations ? [...selectVariations, checkboxVal] : [checkboxVal]);
        setOptionPrice(Number(optionPrice) + Number(price));
        return {
          ...prevChecked,
          [name]: [...checkedInGroup, value]
        };
      } else {
        // Remove the value from the group
        setSelectVariations(selectVariations?.filter(val => val !== checkboxVal) || []);
        setOptionPrice(Number(optionPrice) - Number(price));
        return {
          ...prevChecked,
          [name]: checkedInGroup.filter((v: string) => v !== value)
        };
      }
     
    });
  };
 
  const handleRadioOptionPrice = (event: React.ChangeEvent<HTMLInputElement>, getVariation: any , price: number, checkboxVal: string) => {
    const { value, name } = event.target;
 
    setCheckedCheckboxes(prevChecked => {
      const checkedInGroup = prevChecked[name] || [];
        // Add the value to the group
        return {
          ...prevChecked,
          [name]: [value]
        };
    });
   
    setRadioGroups(prevGroups => {
      // Update the selected radio button's value and price for the specific group
       const updatedGroups = {
        ...prevGroups,
        [name]: {
          value: value,
          price: price
        }
      };
       // Calculate the difference in price for the radio button (old value vs new value)
       const previousPrice = prevGroups[name]?.price || 0;
       const priceDifference = price - previousPrice;
       // Update the option price by adding the price difference
       setOptionPrice(prevPrice => prevPrice + priceDifference);
   
       return updatedGroups;
    }); 

    setSelectVariations((prevVariations) => {
        // Ensure prevVariations is an array
        const updatedVariations = prevVariations ?? [];

        // Remove any previous value associated with the same variation ID
        const newVariations = updatedVariations.filter(
          (val) => !val.startsWith(`${getVariation.variationID}:`)
        );

        // Add the new radio button value
        return [...newVariations, checkboxVal];
      });
  };

  const getOptionsDataForItem = (item: { variations: Variation[]; }) => {
      if (!Array.isArray(item.variations)) {
        return [];
      }
      return item.variations.map(variation_select => {
          // Find the corresponding variation in variationData by matching the id
          const matchedVariation = variation.find(v => v.id.toString() === variation_select.id);
          if (matchedVariation) {
              return {
                  variationID: variation_select.id,
                  variationName: variation_select.name,
                  options: matchedVariation.options_data,
                  required: !!matchedVariation.required,
                  multiple: !!matchedVariation.multiple,
                  min: matchedVariation.min,
                  max: matchedVariation.max
              };
          }
          return null;
      }).filter(variation_select => variation !== null); // Filter out unmatched variations
  };

  const textOptionDescription = (getVariation: any) => {
    return (
      <>&nbsp;
        {getVariation && getVariation.required
          ? <i>เลือกอย่างน้อย  {getVariation.multiple? `${getVariation.min}-${getVariation.max}`: 1} ตัวเลือก</i>
          : <i>ตัวเลือกเสริม {getVariation.min === 0? 'เลือกได้สูงสุด ':`${getVariation.min}-`}{getVariation.max} ตัวเลือก</i>}
      </>
    );
  }

  const variationSelected = (item:T ) => {
    let sumDataPrice = 0;
    getOptionsDataForItem(item).map((getVariation, index) => {
     const maxCheckboxesPerGroup = getVariation?.max ?? 2;
     getVariation?.options.map((option, idx) => {
      const variationString = `${getVariation?.variationID}:${option?.data_name}`;
      const variationID = getVariation.variationID || null;
        if (item.selectVariations && item.selectVariations.includes(variationString)) {
          sumDataPrice += Number(option.data_price);
          const groupKey = getVariation.required && !getVariation.multiple
          ? `radio-group-${variationID}` 
          : `checkbox-group-${variationID}`;
          if (getVariation.required && !getVariation.multiple) {
          
            setCheckedCheckboxes(prevState => ({
              ...prevState,
              [groupKey]: [option?.data_name], // For radio buttons (single selection)
            }));

            setRadioGroups(prevGroups => {
              // Update the selected radio button's value and price for the specific group
               const updatedGroups = {
                ...prevGroups,
                [groupKey]: {
                  value: option?.data_name,
                  price: option?.data_price
                }
              };
               // Calculate the difference in price for the radio button (old value vs new value)
               const previousPrice = prevGroups[groupKey]?.price || 0;
               const priceDifference = option?.data_price - previousPrice;
               // Update the option price by adding the price difference
              // setOptionPrice(prevPrice => prevPrice + priceDifference);
           
               return updatedGroups;
            }); 
          }
          else{
            setCheckedCheckboxes(prevState => ({
              ...prevState,
              [groupKey]: [...(prevState[groupKey] || []), option?.data_name], // For radio buttons (single selection)
            }));
          }
        } 
      });
    });

    setOptionPrice(Number(sumDataPrice));
    setSelectVariations(item.selectVariations || []);
  } 

  const resetSelectItem  = () => {
    setRadioGroups({});
    setCheckedCheckboxes({});
  }

  const matchingOne = loadItems.find(ritem => ritem?.id === item?.id);
  if (matchingOne) {
    item.variations = matchingOne.variations;
    item.price = matchingOne.price;
  }

  let vkey= 0;
  return (
      <Modal show={isOpen} onHide={onClose} className={`${isClosing ? 'hide' : ''}`}>
        <Modal.Header closeButton>
          <Modal.Title>รายการ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {item ? (
            <>
              <div className="d-flex">
                <div className="flex-shrink-0">
                  {Array.isArray(item.photos) && item.photos.length > 0 ? (
                       item.photos.length === 1 ? (
                        <img
                        src={`${baseURL}/uploads/menus/${item.photos}`}
                        alt={`${item.name} 1`}
                        style={{ width: 100, height: 100 }}
                       />
                      ) : (
                        <img
                        src={`${baseURL}/uploads/menus/${item.photos[0]}`}
                        alt={`${item.name} 1`}
                        style={{ width: 100, height: 100 }}
                       />
                      )
                    ) : (
                      <img
                      src='/images/default-image.jpg'
                      style={{ width: 100, height: 100  }} // Adjust height as needed
                      className="" // Make the image round
                      />
                    )}
                </div>
                <div className="flex-grow-1 ms-3">
                  <p><strong>{item.name}</strong></p>
                  <p>{item.description}</p>
                  <p>{item.price.toFixed(2)} บาท</p>
                </div>
              </div>
              <div className='mt-2'>
              <div >
                   {item.variations && getOptionsDataForItem(item).map((getVariation, index) => {
                    /** */
                    return   getVariation &&  (
                    <div key={index} className={`variations-options ${getVariation?.required ? styles.required :''}`}>
                        <p className='m-0'><b>{getVariation && getVariation.variationName}</b>  
                          <small className='text-muted'>
                            {getVariation && textOptionDescription(getVariation)}
                          </small>
                        </p>
                          {getVariation && getVariation.options.map((option, idx) => {
                            if(option.data_open){ 
                              const optType = getVariation.required? (getVariation.multiple? 'checkbox':'radio'):'checkbox';
                              const optName = getVariation.required? (getVariation.multiple? `checkbox-group-${getVariation.variationID}`:`radio-group-${getVariation.variationID}`):`checkbox-group-${getVariation.variationID}`;
                              const isChecked = selectVariations?.includes(`${getVariation.variationID}:${option.data_name}`);
                             
                              return (
                              <div key={vkey++}>
                                 <div className="form-check">
                                            <input 
                                                className="form-check-input" 
                                                type={optType}
                                                value={option.data_name} 
                                                name={optName}
                                                id={`checkbox-${vkey}`}
                                                {...(getVariation.required ? { required: true } : {})}
                                                onChange={(e) =>  {
                                                  if (getVariation.required && !getVariation.multiple) {
                                                    handleRadioOptionPrice(e, getVariation, option.data_price, `${getVariation.variationID}:${option.data_name}`);
                                                  }
                                                  else{
                                                    handleOptionPrice(e, getVariation, option.data_price, `${getVariation.variationID}:${option.data_name}`);
                                                  }
                                                }}
                                                checked={isChecked}
                                                /*{...(checkVariationSelected(item.selectVariations!, getVariation.variationID.toString(), option.data_name)?  { checked: true }: { })} */
                                            />

                                            <label 
                                                className="form-check-label" 
                                                htmlFor={`checkbox-${vkey}`}
                                            >
                                                {option.data_name} +{Number(option.data_price).toFixed(2)}
                                            </label>
                                        </div>
                              </div>
                             )
                           }
                           return null;
                         })}
                    </div>
                   )}

                   )}
                </div>
              </div>
              <div className='mt-2'>
              <p className='m-0'><strong>โน้ต</strong></p>
              <ExpandingTextarea
                  placeholder="เช่น.. ไม่เผ็ด, ไม่หวาน"
                  value={item.note}
                  onChange={(e) => setInputText(e.target.value)}
                />
                </div>
              </>
          ) : (
            <p>No item selected</p>
          )}
          
        </Modal.Body>
        <Modal.Footer>
        {item && (
          <CounterButtonAddCart initialCount={item.quantity} price={item? item.price+optionPrice:0}  usage='update' onUpdateCart={(quantity, totalprice) => {
            const optionsData = getOptionsDataForItem(item!).filter(v => v !== null).map(v => ({
              variationID: v!.variationID.toString(),
              required: v!.required,
              multiple: v!.multiple
            }));
            handleUpdateCart(item.cartItemId!, item!, quantity!, totalprice!, inputText ?? item.note, selectVariations!, optionsData)}}
          onDeleteItem = {() => {onDeleteItem(item); onClose();}}
          onCancel={handleCloseModal} />
        )}
        </Modal.Footer>
      </Modal>
    );
  };
  
  export default ModalEdit;
  