import React, { useState } from 'react';
import { baseURL } from './axiosConfig';
import { Container, Row, Col, Card, Modal, Button, Carousel } from 'react-bootstrap';
import { ItemInterface, Variation, Categories  } from './types';
import CounterButtonAddCart from './CounterButtonAddCart';
import ExpandingTextarea from './ExpandingTextarea';
import styles from './Ullist.module.css';

interface UllistProps<T extends ItemInterface> {
  items: T[];
  variation: Variation[];
  category: Categories[];
  onAddToCart: (item: T, quantity: number, totalprice: number, note: string, select_variations: string[]) => void;
  cart: T[];
  options?: Record<string, any>;
} 

const Ullist = <T extends ItemInterface>({
  items,
  variation,
  category,
  onAddToCart,
  cart,
  options,
}: UllistProps<T>) => {

  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<T | null>(null);
  const [quantity, setQuantity] = useState<number>(1);
  const [totalprice, setTotalprice] = useState<number>(1);
  const [inputText, setInputText] = useState('');
  const [isClosing, setIsClosing] = useState(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [optionPrice, setOptionPrice] = useState<number>(0);
  const [selectVariations, setSelectVariations] = useState<string[] | null>([]);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState<{ [key: string]: string[] }>({});
  const [radioGroups, setRadioGroups] = useState<Record<string, { value: string | null, price: number }>>({});

  const handleSelectedItem = (item: T) => {
    setSelectedItem(item);
    setShowModal(true);
    // Uncomment the next line if you want to add item to cart here
    // onAddToCart(item);
  };
  const handleAddClick = (item: T, quantity: number, totalprice: number, note: string, select_variations: string[],   optionsData: { 
    variationID: string | number;
    required: boolean;
    multiple: boolean;
  }[]
) => {
 
  const missingRequiredVariation = optionsData.some(({ variationID, required, multiple }) => {
    if (!required) return false; // Skip optional variations
    const groupKey = required && !multiple
      ? `radio-group-${variationID}` 
      : `checkbox-group-${variationID}`;

    const checkedInGroup = checkedCheckboxes[groupKey];
     // For radio buttons, we need to check if any option is selected
    if (required && !multiple) {
      return !checkedInGroup || checkedInGroup.length === 0;
    }

    // For checkboxes, ensure at least one option is selected
    return !checkedInGroup || checkedInGroup.length === 0;
  });

  if (missingRequiredVariation) {
    alert('กรุณาระบุตัวเลือก');
    return; // Stop execution if validation fails
  }

    onAddToCart(item, quantity, totalprice, note, select_variations);
    setInputText('');
    setOptionPrice(0);
    setSelectVariations(null);
    setIsClosing(true);
    setCheckedCheckboxes({});
    setTimeout(() => {
      setShowModal(false);
      setIsClosing(false);
    }, 200); // Match the timeout to the animation duration
    setRadioGroups({});
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setTotalprice(1); // Reset quantity or any other logic
    setInputText('');
    setOptionPrice(0);
    setRadioGroups({});
    setSelectVariations(null);
    setCheckedCheckboxes({});
  };
  const handleOptionPrice = (event: React.ChangeEvent<HTMLInputElement>, getVariation: any , price: number, checkboxVal: string) => {
    setIsChecked(event.target.checked);
    const { value, name, checked } = event.target;
    const maxCheckboxesPerGroup = getVariation?.max ?? 2;
    setCheckedCheckboxes(prevChecked => {
      const checkedInGroup = prevChecked[name] || [];

      if (checked && checkedInGroup.length >= maxCheckboxesPerGroup) {
        event.target.checked = false;
        return prevChecked;
      }

      if (checked) {
        // Add the value to the group
        setSelectVariations(selectVariations ? [...selectVariations, checkboxVal] : [checkboxVal]);
        setOptionPrice(Number(optionPrice) + Number(price));
        return {
          ...prevChecked,
          [name]: [...checkedInGroup, value]
        };
      } else {
        // Remove the value from the group
        setSelectVariations(selectVariations?.filter(val => val !== checkboxVal) || []);
        setOptionPrice(Number(optionPrice) - Number(price));
        return {
          ...prevChecked,
          [name]: checkedInGroup.filter((v: string) => v !== value)
        };
      }
     
    });
  };
 
  const handleRadioOptionPrice = (event: React.ChangeEvent<HTMLInputElement>, getVariation: any , price: number, checkboxVal: string) => {
    const { value, name } = event.target;
    setCheckedCheckboxes(prevChecked => {
      const checkedInGroup = prevChecked[name] || [];
        // Add the value to the group
        return {
          ...prevChecked,
          [name]: [value]
        };
    });
   
    setRadioGroups(prevGroups => {
      // Update the selected radio button's value and price for the specific group
       const updatedGroups = {
        ...prevGroups,
        [name]: {
          value: value,
          price: price
        }
      };
       // Calculate the difference in price for the radio button (old value vs new value)
       const previousPrice = prevGroups[name]?.price || 0;
       const priceDifference = price - previousPrice;
       // Update the option price by adding the price difference
       setOptionPrice(prevPrice => prevPrice + priceDifference);
   
       return updatedGroups;
    }); 

    setSelectVariations((prevVariations) => {
        // Ensure prevVariations is an array
        const updatedVariations = prevVariations ?? [];

        // Remove any previous value associated with the same variation ID
        const newVariations = updatedVariations.filter(
          (val) => !val.startsWith(`${getVariation.variationID}:`)
        );

        // Add the new radio button value
        return [...newVariations, checkboxVal];
      });
  };
  const getOptionsDataForItem = (item: { variations: Variation[]; }) => {
    if (!Array.isArray(item.variations)) {
      return [];
    }
    return item.variations.map(variation_select => {
        // Find the corresponding variation in variationData by matching the id
        const matchedVariation = variation.find(v => v.id.toString() === variation_select.id);
        if (matchedVariation) {
            return {
                variationID: variation_select.id,
                variationName: variation_select.name,
                options: matchedVariation.options_data,
                required: !!matchedVariation.required,
                multiple: !!matchedVariation.multiple,
                min: matchedVariation.min,
                max: matchedVariation.max
            };
        }
        return null;
    }).filter(variation_select => variation !== null); // Filter out unmatched variations
 };

 const textOptionDescription = (getVariation: any) => {
  return (
    <>&nbsp;
      {getVariation && getVariation.required
        ? <i>เลือกอย่างน้อย  {getVariation.multiple? `${getVariation.min}-${getVariation.max}`: 1} ตัวเลือก</i>
        : <i>ตัวเลือกเสริม {getVariation.min === 0? 'เลือกได้สูงสุด ':`${getVariation.min}-`}{getVariation.max} ตัวเลือก</i>}
    </>
  );
}
const [searchTerm, setSearchTerm] = useState<string>('');
const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  setSearchTerm(event.target.value);
};
const filtered = items.filter((item) =>
  item.name?.toLowerCase().includes(searchTerm.toLowerCase())
  ||
  item.description?.toLowerCase().includes(searchTerm.toLowerCase())
  ||
  (Array.isArray(item.categories) && item.categories.some(category =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  ))
);
let vkey= 0;


  return (
    <Container className="mt-2">
      <Row className="justify-content-between mb-2">
        {/* Assuming search functionality is already here */}
        <input
        type="text"
        className='form-control mt-4'
        placeholder="พิมพ์ค้นหา เช่น ข้าวผัด, กะเพรา, ยำ, แตงโม"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
        <div className='d-flex justify-content-center mt-2'>
          <Button variant='primary' size="sm" className="me-1 btn-col-4" onClick={(e) => setSearchTerm('')}>เมนูทั้งหมด</Button>
          <Button variant='primary' size="sm" className="me-1 btn-col-4" onClick={(e) => setSearchTerm('บ้านบ้าน')}>เมนูบ้านบ้าน</Button>
          <Button variant='primary' size="sm" className="btn-col-4" onClick={(e) => setSearchTerm('แนะนำ')}>เมนูแนะนำ</Button>
        </div>
        <div className='d-flex mt-2 col-12 col-lg-4'>
          <select className='form-control border-primary'
            id="category-select"
            value={searchTerm ?? ''}
            onChange={handleCategoryChange}
          >
            <option value="">-- ดูหมวดหมู่ --</option>
            {category.map(cat => (
              <option key={cat.id} value={cat.name}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>
      </Row>
      <Row className="justify-content-between">
        {filtered.length > 0 ? (
          filtered.map((item, index) => (
            <Col lg={4} md={6} className='mb-2' key={item.id}>
              <Card className="mb-2 shadow-sm">
              <div className="d-flex flex-row">
              {Array.isArray(item.photos) && item.photos.length > 0 ? (
                      item.photos.length === 1 ? (
                        <Card.Img
                        variant="top"
                        src={`${baseURL}/uploads/menus/${item.photos}`}
                        alt={`${item.name}`}
                        style={{ width: '150px', height: '150px', borderRadius: '5px 0 0 5px', objectFit: 'cover' }}
                      />
                      ) : (
                        <Carousel>
                          {item.photos.map((photo, index) => (
                            <Carousel.Item key={index}>
                              <img
                                src={`${baseURL}/uploads/menus/${photo}`}
                                alt={`${item.name}`}
                                className="d-block w-100 "
                                style={{ maxWidth: '150px', minWidth: '150px', height: '150px', borderRadius: '5px 0 0 5px', objectFit: 'cover' }}
                              />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      )
                    ) : (
                      <Card.Img
                        variant="top"
                        src='/images/default-image.jpg'
                        alt={`${item.name}`}
                        style={{ width: '150px', height: '150px', borderRadius: '5px 0 0 5px', objectFit: 'cover' }}
                      />
                    )}
                <Card.Body className="d-flex flex-column justify-content-between p-2">
                  <div>
                     <p className='fw-bold mb-1'>{item.name}</p>
                     <p className='lh-sm small mb-0'>{item.description}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-primary">
                      <strong>{item.price.toFixed(2)} บาท</strong>
                    </div>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => handleSelectedItem(item)}
                    >
                      เลือก
                    </Button>
                  </div>
                </Card.Body>
                </div>
              </Card>
            </Col>
          ))
        ) : (
          <Col>
            <p>ไม่มีรายการ</p>
          </Col>
        )}
      </Row>

      {/* Modal for Add to Cart Confirmation */}
      <Modal show={showModal} onHide={handleCloseModal} className={`${isClosing ? 'hide' : ''}`}>
        <Modal.Header closeButton>
          <Modal.Title>รายการ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedItem ? (
            <>
              <div className="d-flex">
                <div className="flex-shrink-0">
                  {Array.isArray(selectedItem.photos) && selectedItem.photos.length > 0 ? (
                       selectedItem.photos.length === 1 ? (
                        <img
                        src={`${baseURL}/uploads/menus/${selectedItem.photos}`}
                        alt={`${selectedItem.name} 1`}
                        style={{ width: 100, height: 100 }}
                       />
                      ) : (
                        <img
                        src={`${baseURL}/uploads/menus/${selectedItem.photos[0]}`}
                        alt={`${selectedItem.name} 1`}
                        style={{ width: 100, height: 100 }}
                       />
                      )
                    ) : (
                      <img
                      src='/images/default-image.jpg'
                      style={{ width: 100, height: 100  }} // Adjust height as needed
                      className="" // Make the image round
                      />
                    )}
                </div>
                <div className="flex-grow-1 ms-3">
                  <p><strong>{selectedItem.name}</strong></p>
                  <p>{selectedItem.description}</p>
                  <p>{selectedItem.price.toFixed(2)} บาท</p>
                </div>
              </div>
              <div className='mt-2'>
                <div >
                   {selectedItem.variations && getOptionsDataForItem(selectedItem).map((getVariation, index) => (
                    getVariation && (
                    <div key={index} className={`variations-options ${getVariation.required ? styles.required :''}`}>
                        <p className='m-0'><b>{getVariation.variationName}</b>  
                          <small className='text-muted'>
                            {textOptionDescription(getVariation)}
                          </small>
                        </p>
                          {getVariation.options.map((option, idx) => (
                            option.data_open && (
                              <div key={vkey++}>
                                 <div className="form-check">
                                            <input 
                                                className="form-check-input" 
                                                type={getVariation.required? (getVariation.multiple? 'checkbox':'radio'):'checkbox'}
                                                value={option.data_name} 
                                                name={getVariation.required? (getVariation.multiple? `checkbox-group-${getVariation.variationID}`:`radio-group-${getVariation.variationID}`):`checkbox-group-${getVariation.variationID}`}
                                                id={`checkbox-${vkey}`}
                                                {...(getVariation.required ? { required: true } : {})}
                                                onChange={(e) =>  {
                                                  if (getVariation.required && !getVariation.multiple) {
                                                    handleRadioOptionPrice(e, getVariation, option.data_price, `${getVariation.variationID}:${option.data_name}`);
                                                  }
                                                  else{
                                                    handleOptionPrice(e, getVariation, option.data_price, `${getVariation.variationID}:${option.data_name}`);
                                                  }
                                                }}
                                            />
                                            <label 
                                                className="form-check-label" 
                                                htmlFor={`checkbox-${vkey}`}
                                            >
                                                {option.data_name} +{Number(option.data_price).toFixed(2)}
                                            </label>
                                        </div>
                              </div>
                            )
                          ))}
                    </div>
                    )
                ))}
                </div>
              </div>
             
              <div className='mt-2'>
              <p className='m-0'><strong>โน้ต</strong></p>
                <ExpandingTextarea
                  placeholder="เช่น.. ไม่เผ็ด, ไม่หวาน"
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                />
              </div>
            </>
          ) : (
            <p>No item selected</p>
          )}
        </Modal.Body>
        <Modal.Footer>

          <CounterButtonAddCart initialCount={quantity} price={selectedItem? selectedItem.price + optionPrice:0} onAddToCart={(quantity, totalprice) => {
             const optionsData = getOptionsDataForItem(selectedItem!).map(v => ({
              variationID: v!.variationID.toString(),
              required: v!.required,
              multiple: v!.multiple
            }));
            handleAddClick(selectedItem!, quantity!, totalprice!, inputText, selectVariations!, optionsData)}}
           onCancel={handleCloseModal}/>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Ullist;
