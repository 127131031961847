// SlidePane.tsx
import React from 'react';
import './SlidePane.css';

interface SlidePaneProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const SlidePane: React.FC<SlidePaneProps> = ({ isOpen, onClose, children }) => {
    return (
        <>
        <div className={`overlay ${isOpen ? 'visible' : ''}`} onClick={onClose}></div>
        <div className={`slide-pane ${isOpen ? 'open' : ''}`}>
            <div className="slide-pane-content">
                <span className="close-btn" onClick={onClose}>&times;</span>
                {children}
            </div>
        </div>
         </>
    );
};

export default SlidePane;
