import React, { useState, useEffect } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa'; 
import { CiCirclePlus, CiCircleMinus  } from "react-icons/ci";
interface CounterButtonAddCartProps {
  initialCount?: number;
  price?: number;
  usage?: string;
  onAddToCart?: (quantity: number, totalprice: number) => void;
  onUpdateCart?: (quantity: number, totalprice: number) => void;
  onDeleteItem?: () => void;
  onCancel?: () => void;
}

const CounterButtonAddCart: React.FC<CounterButtonAddCartProps> = ({ initialCount = 0, price = 0,  usage = 'add', onAddToCart, onUpdateCart, onDeleteItem, onCancel  }) => {
  const [quantity, setCount] = useState(initialCount);
  const [totalprice, setTotalprice] = useState(price);
   // Set totalPrice on component load
   useEffect(() => {
    setTotalprice(quantity *  price);
  }, [initialCount, price]); // Dependencies ensure this runs on load and if quantity or price changes
  
  const increment = () => {
    setCount(quantity + 1);
    setTotalprice((quantity+1) * price );
  }
  const decrement = () => {
    if( quantity > 0 ){
     setCount(quantity - 1);
     setTotalprice((quantity-1) * price );
    }
  }
  
  return (
    <>
    <div className="d-flex justify-content-between w-100">
        <CiCircleMinus size={46} role="button" className="text-primary" onClick={decrement} />
        <FormControl 
          type="text" 
          value={quantity} 
          readOnly 
          className="text-center border-0" 
          style={{ maxWidth: '60px' }} 
        />
        <CiCirclePlus size={46} role="button" className="text-primary" onClick={increment} />
     
      {quantity > 0 ? (
       <Button variant="primary" className="ms-4 w-100"  disabled={!quantity}   onClick={() => {
        usage === 'add'? onAddToCart && onAddToCart(quantity, totalprice): onUpdateCart && onUpdateCart(quantity, totalprice);
      }} >
       {usage === 'add'? 'ลงรายการ':'บันทึกรายการ'} - {totalprice.toFixed(2)}
     </Button>
      ) : (
        <Button variant="danger" className="ms-4 w-100" onClick={usage === 'add' ? onCancel: onDeleteItem}>
          {usage === 'add' ? (
            'ยกเลิก' // Display "Cancel" text in Thai
          ) : (
            <FaTrash size={24} role="button" />
          )}
        </Button>
      )}
    </div>
    </>
  );
};

export default CounterButtonAddCart;
