import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';
import {  Menus, MenusResponse, ItemInterface, Variation, Categories, HistoryEntry,  Order, ServerResponse } from './types';
import  Ullist from './Ullist';
import { Badge, Container, Modal, Button, Navbar, Nav, Table } from 'react-bootstrap';
import {  FaEdit, FaTrash } from 'react-icons/fa'; 
import { RiFilePaper2Line, RiHistoryFill } from "react-icons/ri";
import { v4 as uuidv4 } from 'uuid';
import SlidePane from './SlidePane';
import ModalEdit from './ModalEdit';
import Swal from 'sweetalert2';

const App: React.FC = () => {
  const [menus, setMenus] = useState<Menus[]>([]);
  const [variation, setVariation] = useState<Variation[]>([]);
  const [category, setCategory] = useState<Categories[]>([]);
  const [showCartModal, setShowCartModal] = useState(false);
  const [cart, setCart] = useState<ItemInterface[]>([]);
  const [hcart, setHCart] = useState<HistoryEntry[]>([]);

  useEffect(() => {
    fetchMenus();
    const storedCart = localStorage.getItem('cart');
    if (storedCart) {
      try {
        const parsedCart = JSON.parse(storedCart);
        setCart(parsedCart);
      } catch (error) {
        
      }
    }
    const storedHCart = localStorage.getItem('hcart');
    if (storedHCart) {
      try {
        const parsedHCart = JSON.parse(storedHCart);
        setHCart(parsedHCart);
      }  catch (error) {
        
      }
    }
    // Set a flag to indicate initial data loading is complete
    setIsInitialLoadComplete(true); 
  }, []);
  
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
  useEffect(() => {
    if (isInitialLoadComplete) { // Only update localStorage after initial load
      localStorage.setItem('cart', JSON.stringify(cart));
    }
  }, [cart, isInitialLoadComplete]); 

  const fetchMenus = async () => {
    const response = await axiosInstance.get<MenusResponse>('/menu/list');
    setMenus(response.data.page); 
    setVariation(response.data.variation);
    setCategory(response.data.category);
  };

  const handleAddToCart = (item: ItemInterface, quantity: number, totalprice: number, note: string, select_variations: string[]) => {
    const cartItemId = uuidv4(); // Generate a unique ID
    setCart([...cart, { ...item, quantity:quantity, totalprice:totalprice, cartItemId, note:note, selectVariations: select_variations }]);
  };

  const handleUpdateCart = (cartItemId:string, item: ItemInterface, quantity: number, totalprice: number, note: string, select_variations: string[]) => {
    setCart((cart) => { 
      const existingItemIndex = cart.findIndex(cartItem => cartItem.cartItemId === cartItemId);
      if (existingItemIndex !== -1) {
        // If the item exists, create a new array with the updated item
        const updatedCart = [...cart];
        updatedCart[existingItemIndex] = {
          ...updatedCart[existingItemIndex],
          quantity: quantity,
          totalprice: totalprice,
          note: note,
          selectVariations: select_variations
        };
        return updatedCart;
      } else {
        // If the item doesn't exist, add it to the cart
        return [...cart, { ...item, quantity: quantity, totalprice: totalprice, cartItemId, note: note, selectVariations: select_variations }];
      }
    });
  };

  const handleOpenCart = () => {
    setShowCartModal(true);
  };

  const handleCloseCart = () => {
    setShowCartModal(false);
  };
  
  const handleDeleteCartItem = (itemToRemove: ItemInterface) => {
    const updatedCart = cart.filter(item => item.cartItemId !== itemToRemove.cartItemId);
    setCart(updatedCart);
  };

  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const openPane = () => {
    fetchMenus();
    setIsPaneOpen(true);
  }
  const closePane = () => setIsPaneOpen(false);
  const [isPaneHisOpen, setIsPaneHisOpen] = useState(false);
  const openPaneHis = () => setIsPaneHisOpen(true);
  const closePaneHis = () => setIsPaneHisOpen(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  
  const [selectedItem, setSelectedItem] = useState<ItemInterface | any>(null);
  const handleOpenModal = (itemEdit: ItemInterface) => {
    setSelectedItem(itemEdit);
    setIsModalOpen(true)
  };

  const handleOpenModalAdd = (item: ItemInterface) => {
    item.cartItemId = uuidv4();
    setSelectedItem(item);
    setIsModalOpen(true)
  };

  const totalPrice = cart.reduce((sum, item) => sum + (item.totalprice??0), 0);
  const extractTextAfterColon = (data: string[]) => {
    return data.map(item => {
      const parts = item.split(':');
      return parts[1] ? parts[1].trim() : '';
    });
  };

  const [orderId, setOrderId] = useState<string | null>(null);

    const handleSubmit = async (event: React.FormEvent) => {
      event.preventDefault();
    
      if(cart && cart.length <= 0)
        return false;

      const hasClosedItem = cart.some(item => item.status === '_close');
      if(hasClosedItem){
        Swal.fire({
          title: 'เกิดข้อผิด���ลาด',
          html: '<p>มีบางรายการหมด</p>',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return false;
      }
        
      // Send order via HTTP POST request
      try {
        const response = await axiosInstance.post('/orders',{u: userUUID, orderDetails: cart});
/*
        var furl = 'http://192.168.1.100:3001/api/orders'; // websocket
        furl = 'https://foodorder.baan-food.com/api/orders'; // 
        const response = await fetch(furl, {
          method: 'POST'
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({u: userUUID, orderDetails: cart})
        });
  */
        if (response.status === 200) {
          const result = await response.data;
          const newEntry: HistoryEntry = {
            orderNo: result.orderId,
            items: cart,
          };
          setHCart((prevHCart) => {
            const updatedHCart =  [newEntry, ...prevHCart].slice(0, 3);
            localStorage.setItem('hcart', JSON.stringify(updatedHCart));
            return updatedHCart;
          });
          setCart([]);
          setIsPaneOpen(false);
          Swal.fire({
            title: 'ส่งออเดอร์แล้ว',
            html: '<h3>ออเดอร์หมายเลข: '+result.orderId+'</h3><p>ทางร้านใช้เวลาทำอาหาร 10-15 นาที</p><p>กรุณารอสักครู่...</p>',
            icon: 'success',
            confirmButtonText: 'OK'
          });
        } else {
          console.error('Failed to place order:', response.data);
        }
      } catch (error) {
        console.error('An error occurred:', error);
        Swal.fire({
          title: 'เกิดข้อผิดพลาด',
          text: 'ไม่สามารถส่งออเดอร์ได้ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        }
    };
  
  // Check if a UUID already exists in localStorage
  function getOrCreateUUID() {
    let uuid = localStorage.getItem('_u');
    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem('_u', uuid);
    }
    return uuid;
  } 
  
  // Call this when the user visits your site
  const userUUID = getOrCreateUUID();

  const [modalUsage, setModalUsage] = useState('update');

  cart.map(citem => {
    let sumDataPrice = 0;
    const matchingNewObj = menus.find(mitem => mitem.id === citem.id);
    sumDataPrice = matchingNewObj?.price || 0;
    citem.variations && citem.variations.map(variation_c => {
      const matchedVariation = variation.find(v => v.id.toString() === variation_c.id);
      if (matchedVariation) {
         matchedVariation.options_data.map((option, idx) => {
           const stringSelectVariations = `${matchedVariation.id}:${option?.data_name}`;
            if (citem.selectVariations && citem.selectVariations.includes(stringSelectVariations)){
              if(option.data_open) {
                sumDataPrice += Number(option.data_price);
              }
              else{
                citem.selectVariations =  citem.selectVariations.filter(item => item !== stringSelectVariations);
              }
            }
         });
      } 
      
    });

    if (citem.selectVariations && citem.selectVariations.length > 0) {
      const originalSelections = citem.selectVariations;
      const updatedSelections = originalSelections.filter(variationString => {
        const [variationId, optionName] = variationString.split(":");
        const cvariation = variation.find(v => v.id === parseInt(variationId, 10));
        return cvariation && cvariation.options_data.some((option: { data_name: string; }) => option.data_name === optionName);
      });

      // Only update if there are changes
      if (updatedSelections.length > 0 && citem.selectVariations.length !== updatedSelections.length) {
        citem.selectVariations = updatedSelections;
      }
      
    }


    if (matchingNewObj) {
      citem.status = (citem.price !=  matchingNewObj.price) || (citem.variations !=  matchingNewObj.variations)  || (citem.totalprice !=  sumDataPrice)? '': '';
      citem.totalprice = sumDataPrice * (citem.quantity ?? 1);
      citem.open = matchingNewObj.open;
      citem.variations =  matchingNewObj.variations;
    }
    else{
      citem.open = 0;
      citem.totalprice = 0;
      citem.status = '_close';
    }
  });

  return (
    <>
    <Navbar bg="light" expand="lg" fixed="top">
        <Container >
          <Navbar.Brand >BAAN สั่งอาหาร</Navbar.Brand>
          <div className="d-flex">
              <Nav.Link className="me-4" onClick={openPaneHis}>
                <RiHistoryFill size={28} />
              </Nav.Link>
              <Nav.Link  onClick={openPane}>
                <RiFilePaper2Line size={28} />
                <Badge pill bg="primary" className="ml-1">
                  {cart.length} 
                </Badge>
              </Nav.Link>
          </div>
        </Container>
      </Navbar>
    <div className="container mt-5">
      <ModalEdit isOpen={isModalOpen} onClose={closeModal} onUpdateCart={handleUpdateCart} onDeleteItem={handleDeleteCartItem} item={selectedItem} variation={variation} loadItems={menus} />
      <Ullist
        items={menus}
        variation={variation}
        category={category}
        onAddToCart={handleAddToCart} 
        cart={cart} // Pass the cart state
      />
    </div>
    <SlidePane isOpen={isPaneHisOpen} onClose={closePaneHis}>
      <div className=" mt-4">
         <div className="row">
         <h2>ประวัติส่งรายการ</h2>
         <div className='div-scroll'>
      {hcart.length === 0 ? (
        <p>ไม่มีประวัติ.</p>
      ) : (
        hcart.map((entry, idx) => {
          var htotalPrice = 0;
          return (
          <div key={idx}>
            <h4>ออเดอร์หมายเลข {entry.orderNo}</h4>
            <ol>
              {entry.items.map((item) => {
                const extractedVariations = item.selectVariations ? extractTextAfterColon(item.selectVariations) : [];
                htotalPrice += item.totalprice ?? 0;
                return (
                <li key={item.cartItemId} className='mt-2'>
                  <div className='d-flex justify-content-between'>
                    <div>
                    {item.name} - จำนวน {item.quantity} - ราคา {item.totalprice?.toFixed(2)}
                    {extractedVariations.map((text, idx) => (
                      <p key={idx} className='m-0 ms-2 small '>{text}</p>
                    ))}
                    <p className='m-0 ms-2  text-warning2 fw-bold'>{item.note}</p>
                    </div>
                    <div>
                    <Button variant='primary' size="sm" 
                    onClick={(e) => {
                      handleOpenModalAdd(item);
                    }}>สั่งอีกครั้ง
                    </Button>
                    </div>
                  </div>
                </li>
              )})}
            </ol>
            <h6 className=''>รวม {htotalPrice.toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} บาท</h6>
            <hr/>
          </div>
        )})
      )}
          </div>
         </div>
      </div>
    </SlidePane>
    <SlidePane isOpen={isPaneOpen} onClose={closePane}>
      <div className="mt-4">
          {cart.length > 0 ? (
            <>
              <div className="table-responsive table-scroll mb-2">
                <table className="table">
                <thead>
                  <tr>  
                    <th scope="col"></th>
                    <th scope="col" className="text-center">รายการ</th>
                    <th scope="col" className="text-center">จำนวน</th>
                    <th scope="col" className="text-center">ราคา</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
              {cart.map((item, index) => {
                 // Extract the text after the colon from selectVariations
                const extractedVariations = item.selectVariations ? extractTextAfterColon(item.selectVariations) : [];

              return (
                  <tr key={index}>
                    <td className="p-0 pt-2"><span className='badge bg-secondary'>{index+1}</span></td>
                    <td>
                      {item.status ? ( item.status == '_close' ? (
                           <span className='badge bg-danger me-1'>หมด</span> 
                         ) : item.status == '_update' ? (
                          <span className='badge bg-warning me-1'>มีเปลี่ยนแปลง</span> 
                         ) : ''
                        ) : ''
                      }
                      {item.name}
                      {extractedVariations.map((text, idx) => (
                        <p key={idx} className='m-0 ms-2 small '>{text}</p>
                      ))}
                      <p className='m-0 text-warning2 fw-bold'>{item.note}</p>
                    </td>
                    <td className="text-center">{item.quantity}</td>
                    <td>
                        {item.totalprice?.toFixed(2)}
                    </td>
                    <td>
                      <div className='pb-2 text-center'>
                        <FaEdit
                          size={24}
                          className="text-primary"
                          onClick={() => handleOpenModal(item)}
                          role="button"
                        />
                      </div>
                      <div className='pt-2 text-center' >
                        <FaTrash
                          size={14}
                          className="text-danger"
                          onClick={() => handleDeleteCartItem(item)}
                          role="button"
                        />
                      </div>
                    </td>
                </tr>
              );
            })}
               </tbody>
               </table>
               </div>
              </>
          ) : (
            <div className="table-scroll mb-2">
             <p>ไม่มีรายการสั่งอาหาร</p>
            </div>
          )}
          <div className='d-flex justify-content-between'>
            <h2>{totalPrice.toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} บาท</h2>
            <div>
              <Button variant='secondary' className="me-2" onClick={closePane}>ปิด</Button>
              <Button variant='primary' size="lg"  onClick={handleSubmit} 
              {...(cart && cart.length <= 0? { disabled: true } : {})}
              >ส่งรายการ</Button>
            </div>
           </div>
        </div>
       </SlidePane>
    </>
  );
};

export default App;
